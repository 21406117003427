<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="合同编号" name="code"></c-form-item>
					<c-form-item type="input" label="合同名称" name="name"></c-form-item>
					<c-form-item type="input" label="甲方" name="yzdw_name"></c-form-item>
					<c-form-item type="input" label="乙方" name="zbqy_name"></c-form-item>
					<c-form-item v-if="scene != 'approve'" type="select" label="签署状态" name="qs_state" :options="contractStateList"></c-form-item>
					<c-form-item type="datetime" label="申请时间" start-name="start_date" end-name="end_date"></c-form-item>
				</c-form>
			</m-search>
			
			<m-operate>
				<c-button icon="add" @click="navigateTo('/contract_create')">新建合同</c-button>
			</m-operate>
			
			<c-table ref="table">
				<c-table-column
					label="项目状态"
					width="130"
				>
					<template v-slot="{data}">
						<span v-if="contractState[data.qs_state]" :style="{'color': contractState[data.qs_state].color}">{{contractState[data.qs_state].name}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="合同编号"
					name="code"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="合同名称"
					name="name"
					width="150"
				></c-table-column>
				
				<c-table-column
					label="甲方单位"
					name="yzdw_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="乙方单位"
					name="zbqy_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="签约截止日"
					name="qy_last_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					label="签约完成日"
					name="qy_qs_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					name="create_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="120"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button v-if="data.qs_state == 4" @click="navigateTo('/contract_edit', {id: data.id})">编辑</c-table-button>
						<c-table-button v-if="data.qs_state == 0" @click="navigateTo('/contract_detail', {id: data.id})">审批</c-table-button>
						<c-table-button v-else-if="data.qs_state == 1" @click="navigateTo('/contract_detail', {id: data.id})">签署</c-table-button>
						<c-table-button v-else @click="navigateTo('/contract_detail', {id: data.id})">详情</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
	</page>
</template>

<script>
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		props: {
			scene: String
		},
		
		computed: {
			...mapState(['contractStateList']),
			...mapGetters(['contractState'])
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data = {}) {
				if (this.scene == 'approve') {
					data.qs_states = [0, 1];
				}
				this.$refs.table.load({
					url: '/project/ht/list',
					data
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>