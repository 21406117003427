<template>
	<contract-list scene="approve"></contract-list>
</template>

<script>
	import contractList from './contract_list_pc.vue'
	
	export default {
		name: 'contract_approve',
		
		components: {
			contractList
		}
	};
</script>

<style lang="stylus" scoped>
</style>